<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑榜单' : '新增榜单'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 模块名称 -->
      <a-form-item
        label="模块名称"
        name="name"
        :rules="[{ required: true, message: '请输入模块名称' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
          :maxlength="10"
        />
      </a-form-item>
      <!-- 顺序 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        label="短剧"
        :rules="[{ required: true }]"
      >
        <!-- 单条数据 -->
        <a-form-item
          class="play-item"
          v-for="(play, index) in formState.plays"
          :key="index"
          :rules="[{ required: true, message: '请选择短剧' }]"
          :name="['plays', index, 'id']"
        >
          <a-select
            style="width: 350px"
            show-search
            placeholder="请选择"
            v-model:value="play.id"
            :filter-option="filterOption"
            @change="changeDrama(index, $event)"
          >
            <a-select-option
              v-for="drama in dramaList"
              :key="`${drama.id}-${drama.remark_name}`"
              :value="drama.id"
            >
              {{ drama.remark_name }}
            </a-select-option>
          </a-select>
          <!-- 第一组不允许上移 -->
          <a-button
            type="link"
            :disabled="index === 0"
            @click="move(index, 'up')"
          >
            上移
          </a-button>
          <!-- 最后一组不允许下移 -->
          <a-button
            type="link"
            :disabled="index === formState.plays.length - 1"
            @click="move(index, 'down')"
          >
            下移
          </a-button>
          <!-- 唯一一组不允许删除 -->
          <a-button
            type="link"
            :disabled="index === 0 && formState.plays.length === 1"
            @click="deletePlay(index)"
          >
            删除
          </a-button>
        </a-form-item>
      </a-form-item>
      <div style="margin: -20px 0 0 112px;">
        <a @click="addPlay">新增位置</a>
      </div>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { FrontSource} from '@/utils/constantList'
import { frontSourceDetail, frontTopEdit, projectDramaAll } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// source类型
const source = FrontSource.top
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// platform
let platform = ref(undefined)
// id
let id = ref(undefined)
// 短剧列表
let dramaList = ref([])
// 剧集信息
let formState = reactive({
  // 文案
  name: undefined,
  // 顺序
  sort: undefined,
  // 短剧
  plays: [{
    name: undefined,
    id: undefined
  }]
})

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 当前平台
  platform.value = params.platform
  // id
  id.value = params.id
  // 获取短剧列表（在列表中获取详情）
  getProjectDramaAll()
}

// 短剧列表
function getProjectDramaAll () {
  isLoading.value = true
  projectDramaAll({ platform_id: platform.value }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dramaList.value = data
      // 获取配置详情
      if (id.value) {
        getFrontSourceDetail()
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取配置详情
function getFrontSourceDetail () {
  isLoading.value = true
  const params = {
    source,
    id: id.value
  }
  frontSourceDetail(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 名称
      formState.name = data.name
      // 排序
      formState.sort = data.sort
      // 短剧
      formState.plays = data.content
      // 将配置中已经下架的短剧，拼在列表的后面（保证正常展示）
      dramaList.value = Pub.mergeArr(dramaList.value, data.content, 'id')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
    formState.plays = [{
      name: undefined,
      id: undefined
    }]
  }
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 修改短剧选中：把选中剧的 name 塞进 formState.plays 中
function changeDrama (index, e) {
  formState.plays[index].name = dramaList.value.filter(item => item.id === e)[0].name
}

// 新增短剧位
function addPlay () {
  formState.plays.push({
    name: undefined,
    id: undefined
  })
}

// 删除短剧
function deletePlay (index) {
  formState.plays.splice(index, 1)
}

// 移动短剧
function move (index, type) {
  // 保存当前项
  const temp = formState.plays[index]
  if (type === 'up') {
    // 处理上移
    formState.plays[index] = formState.plays[index - 1]
    formState.plays[index - 1] = temp
  } else {
    // 处理下移
    formState.plays[index] = formState.plays[index + 1]
    formState.plays[index + 1] = temp
  }
}

// 表单校验
function validator (rule, value) {
  // 封面
  if (rule.field === 'sort') {
    if (!value) {
      return Promise.reject('请输入顺序')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      platform_id: platform.value,
      id: id.value,
      name: formState.name,
      sort: formState.sort,
      content: formState.plays,
    }
    frontTopEdit(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.play-item {
  margin-bottom: 16px;
  .ant-btn-link {
    padding: 0;
    margin-left: 8px;
  } 
}

</style>