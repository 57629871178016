<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>前台配置</a-breadcrumb-item>
    <a-breadcrumb-item>首页榜单</a-breadcrumb-item>
  </a-breadcrumb>
  <a-tabs class="tab-box" v-model:activeKey="activeKey">
    <a-tab-pane v-for="item in Platforms" :key="item.value" :tab="item.text">
      <!-- 每个平台对应的模板 -->
      <PlatformTemplate
        :platform="item.value"
        v-if="activeKey === item.value"
      />
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { Platforms } from '@/utils/constantList'
import PlatformTemplate from './components-rankings/PlatformTemplate'

// tabkey
let activeKey = ref(1)

</script>

<style lang="less" scoped>
.tab-box {
  margin: 20px 0;
  :deep(.ant-tabs-tab) {
    padding: 12px 30px;
  }
}
</style>